<template>
	<div>
		<div class="mt-8 osahan-categories w-full px-4 pb-4">
			<div class="text-center text-5 text-grey-800 mb-2">
				FOR A BETTER <br />
				SERVICE EXPERIENCE
			</div>
			<div class="row m-0">
				<div class="col-6 p-3 text-center">
					<router-link
						:to="{ name: 'restaurants' }"
						class="btn bg-white shadow-sm rounded px-2 py-4 c-it text-center  mr-auto"
						style="width: 90%;"
					>
						<p class="m-0 pt-2 text-muted text-center">
							<img
								:src="
									`${$root.env.VUE_APP_PUBLIC_PATH}img/precommander-menu.png`
								"
								class="img-fluid mx-auto icons-home icons-ske"
								alt="Responsive image"
							/>
						</p>
						<p class="m-0 pt-2 text-muted text-center">
							Pre-order
						</p>
					</router-link>
				</div>
				<div class="col-6 p-3 text-center ">
					<router-link
						:to="{
							name: 'restaurants',
							query: { isReservingTable: true }
						}"
						class="btn bg-white shadow-sm rounded px-2 py-4 c-it text-center  mr-auto"
						style="width: 90%;"
					>
						<p class="m-0 pt-2 text-muted text-center">
							<img
								:src="
									`${$root.env.VUE_APP_PUBLIC_PATH}img/reserver-menu.png`
								"
								class="img-fluid mx-auto icons-home icons-ske"
								alt="Responsive image"
							/>
						</p>
						<p class="m-0 pt-2 text-muted text-center">
							Book your table
						</p>
					</router-link>
				</div>
			</div>
			<div class="row m-0">
				<div class="col-6 p-3 text-center ">
					<router-link
						:to="{ name: 'qrscanner' }"
						class="btn bg-white shadow-sm rounded px-2 py-4 c-it text-center mr-auto"
						style="width: 90%;"
					>
						<p class="m-0 pt-2 text-muted text-center">
							<img
								:src="
									`${$root.env.VUE_APP_PUBLIC_PATH}img/qr-code-menu.png`
								"
								class="img-fluid mx-auto icons-home icons-ske"
								alt="Responsive image"
							/>
						</p>
						<p class="m-0 pt-2 text-muted text-center">
							Q/R Code
						</p>
					</router-link>
				</div>
				<div class="col-6 p-3 text-center ">
					<router-link
						:to="{ name: 'qrscanner', query: { callserver: true } }"
						class="btn bg-white shadow-sm rounded px-2 py-4 c-it text-center mr-auto"
						style="width: 90%;"
					>
						<p class="m-0 pt-2 text-muted text-center">
							<img
								:src="
									`${$root.env.VUE_APP_PUBLIC_PATH}img/cloche-menu.svg`
								"
								class="img-fluid mx-auto icons-home icons-ske"
								alt="Responsive image"
							/>
						</p>
						<p class="m-0 pt-2 text-muted text-center">
							Call the waiter
						</p>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getMobileOperatingSystem } from '@/tools/helpers';
// import SearchInput from '../../components/SearchInput.vue';
export default {
	// components: { SearchInput },
	mounted: function() {
		this.$root.isLoading = true;
		setTimeout(() => {
			this.$root.isLoading = false;

			let element = document.body;
			if (this.$root.isInsideIframe) {
				element = document.getElementsByTagName('iframe')[0]
					.contentDocument.body;
			}
			element.style.backgroundImage = `url("${require('@/assets/images/bg1.jpg')}")`;

			let mobileOS = getMobileOperatingSystem();
			if (
				this.$route.query.redirect &&
				['ios', 'android'].includes(mobileOS)
			) {
				if (mobileOS == 'ios')
					window.location.href =
						'https://apps.apple.com/us/developer/utopie/id1230101305?l=fr';
				else if (mobileOS == 'android')
					window.location.href =
						'https://play.google.com/store/apps/developer?id=UTOPIE+Maroc';
			}
		}, 250);
	}
};
</script>

<style lang="scss" scoped>
.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.mr-auto {
	margin: auto !important;
}
.icons-intro {
	color: #c20016;
	font-size: 63px;
}

.borsearch-intro {
	border: 1px solid #9e9e9e;
}
</style>
